import { RouteLinkProps } from "../types/global";
import {
  URL_METHODIST_MAIN,
  URL_METHODIST_REPORTS,
  URL_AUTH_JSHIR_REGISTER,
  URL_AUTH_LOGIN,
  URL_AUTH_REGISTER,
  URL_AUTH_RESET_PASSWORD,
  URL_TEACHER_CHECK_MYSELF,
  URL_TEACHER_MAIN,
  URL_TEACHER_REPORTS,
  URL_METHODIST_MAIN_VIEW,
  URL_METHODIST_MAIN_EVALUATION,
  URL_METHODIST_REPORTS_VIEW,
  URL_TEACHER_MAIN_VIEW,
  URL_TEACHER_MAIN_NEW,
  URL_TEACHER_REPORTS_VIEW,
  URL_TEACHER_REPORTS_NEW,
  PROFILE,
  URL_ADMIN_REPORTS_NEW,
  URL_ADMIN_MAIN,
  URL_ADMIN_MAIN_VIEW,
  URL_ADMIN_MAIN_NEW,
  URL_ADMIN_REPORTS_VIEW,
  URL_ADMIN_METHODISTS,
  PROFILE_SENDMESSAGE,
  CHANGE_PASSWORD,
  URL_ADMIN_MAIN_EVALUATION,
  URL_ADMIN_REPORTS,
  URL_ADMIN_REPORTS_CHANGE_NAME,
  URL_ADMIN_METHODISTS_VIEW,
  URL_ADMIN_METHODISTS_NEW,
  URL_LANDING_HOME,
  URL_FILTER_MANUALS,
  URL_METHODIST_APPEALS,
  URL_ADMIN_APPEALS,
  URL_ADMIN_APPEALS_VIEW, URL_TEACHER_HOME,
} from "./page_urls";
import pages from "../pages";
import components from "../components";
import {ReportChangeNameViewPage} from "../pages/admin/ReportChangeNameView";

export const methodistPages: RouteLinkProps[] = [
  {
    component: pages.methodist.StatisticsPage,
    path: URL_METHODIST_MAIN,
  },
  {
    component: pages.methodist.AppealsPage,
    path: URL_METHODIST_APPEALS,
  },
  {
    component: pages.methodist.AppealViewPage,
    path: URL_METHODIST_MAIN_VIEW,
  },
  {
    component: pages.methodist.DVEvaluationPage,
    path: URL_METHODIST_MAIN_EVALUATION,
  },
  {
    component: pages.methodist.ReportsPage,
    path: URL_METHODIST_REPORTS,
  },
  {
    component: pages.methodist.ReportViewPage,
    path: URL_METHODIST_REPORTS_VIEW,
  },
  {
    component: pages.toAll.Cabinet,
    path: PROFILE,
  },
  {
    component: pages.toAll.SendMessagePage,
    path: PROFILE_SENDMESSAGE
  },
  {
    component: pages.toAll.ChangePasswordPage,
    path: CHANGE_PASSWORD
  }
];

export const teacherPages: RouteLinkProps[] = [
  {
    component: pages.teacher.HomePage,
   path:URL_TEACHER_HOME
  },
  {
    component: pages.teacher.AppealsPage,
    path: URL_TEACHER_MAIN,
  },
  {
    component: pages.teacher.AppealViewPage,
    path: URL_TEACHER_MAIN_VIEW,
  },
  {
    component: pages.teacher.AppealNewPage,
    path: URL_TEACHER_MAIN_NEW,
  },
  {
    component: pages.teacher.CheckMyselfPage,
    path: URL_TEACHER_CHECK_MYSELF,
  },
  {
    component: pages.teacher.ReportsPage,
    path: URL_TEACHER_REPORTS,
  },
  {
    component: pages.teacher.ReportViewPage,
    path: URL_TEACHER_REPORTS_VIEW,
  },
  {
    component: pages.teacher.ReportNewPage,
    path: URL_TEACHER_REPORTS_NEW,
  },
  {
    component: pages.toAll.Cabinet,
    path: PROFILE,
  },
  {
    component: pages.toAll.SendMessagePage,
    path: PROFILE_SENDMESSAGE
  },
  {
    component: pages.toAll.ChangePasswordPage,
    path: CHANGE_PASSWORD
  }
];

export const adminPages: RouteLinkProps[] = [
  {
    component: pages.admin.StatisticsPage,
    path: URL_ADMIN_MAIN,
  },
  {
    component: pages.admin.AppealsPage,
    path: URL_ADMIN_APPEALS,
  },
  {
    component: pages.admin.AppealViewPage,
    path: URL_ADMIN_APPEALS_VIEW,
  },
  {
    component: pages.admin.DVEvaluationPage,
    path: URL_ADMIN_MAIN_EVALUATION,
  },
  {
    component: pages.admin.MethodistsPage,
    path: URL_ADMIN_METHODISTS,
  },
  {
    component: pages.admin.MethodistViewPage,
    path: URL_ADMIN_METHODISTS_VIEW,
  },
  {
    component: pages.admin.MethodistNewPage,
    path: URL_ADMIN_METHODISTS_NEW,
  },
  {
    component: pages.admin.ReportsPage,
    path: URL_ADMIN_REPORTS,
  },
  {
    component: pages.admin.ReportViewPage,
    path: URL_ADMIN_REPORTS_VIEW,
  },
  {
    component: pages.admin.ReportChangeNameViewPage,
    path: URL_ADMIN_REPORTS_CHANGE_NAME
  },
  {
    component: pages.toAll.Cabinet,
    path: PROFILE,
  },
  {
    component: pages.toAll.ChangePasswordPage,
    path: CHANGE_PASSWORD
  }
];

export const authPages: RouteLinkProps[] = [
  {
    component: pages.auth.Login,
    path: URL_AUTH_LOGIN,
  },
  {
    component: pages.auth.RestorePassword,
    path: URL_AUTH_RESET_PASSWORD,
  },
  {
    component: pages.auth.Registration,
    path: URL_AUTH_REGISTER,
  },
  {
    component: pages.auth.RegistrationJSHIR,
    path: URL_AUTH_JSHIR_REGISTER,
  },
];

export const landingPages: RouteLinkProps[] = [
  {
    component: pages.landing.HomePage,
    path: URL_LANDING_HOME
  },
  {
    component: pages.landing.FilterManualsPage,
    path: URL_FILTER_MANUALS
  }
]
