import React, {ChangeEvent, useEffect, useState} from 'react'
import CountUp from "react-countup";
import {useQuery} from "react-query";
import {getAcademicYears, getLandingAppealStatistics} from "../../../../api/_requests";
import {localTranslate} from "../../../../helpers";
import {queryCacheOptions} from "../../../../mock/cacheOptions";

export const ResultsSection = () => {
  const [selectedAcademicYear, setSelectedAcademicYear] = useState<string>("")


  const { data: academicYearsData } = useQuery("getAcademicYears", getAcademicYears, {
    ...queryCacheOptions,
    onSuccess: (data) => {
      if (data?.data?.length > 0) {
        setSelectedAcademicYear(data.data[0].name);
      }
    }
  });


  const { data: appealStatisticsData, refetch:refetchAppealStats } = useQuery(
      ["getLandingAppealStatistics", selectedAcademicYear],
      () => getLandingAppealStatistics(selectedAcademicYear),
      {
        enabled: !!selectedAcademicYear,
        ...queryCacheOptions
      }
  );

  useEffect(() => {
    refetchAppealStats();
  }, []);


  const handleChangeAcademicYears = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedAcademicYear(e.target.value);
  };


  return (
      <div className={"my-20"}>
        <div className="container text-center">
          <div className={"display-6 my-10 d-flex justify-content-center gap-5"}>
            <select
                name="academicYear"
                onChange={handleChangeAcademicYears}
                className={"bg-transparent border-0"}
                id="academicYear"
                value={selectedAcademicYear}
            >
              {
                academicYearsData?.data?.map((academicYear) => (
                    <option key={academicYear.id} value={academicYear.name}>{academicYear.name}</option>
                ))
              }
            </select>
            {localTranslate("o'quv yili natijalari", "pезультаты учебного года", "results of the academic year")}
          </div>

          <div className="row row-gap-5">
            <div className="col-md-4">
              <div>
                <CountUp end={appealStatisticsData?.results?.total_appeals || 0} separator={" "}
                         className={"display-6 text-primary"} />
                <p className={"fs-5"}>{localTranslate("Arizalar", "Апелляции", "Appeals")}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <CountUp end={appealStatisticsData?.results?.rated_appeals || 0} separator={" "}
                         className={"display-6 text-primary"} />
                <p className={"fs-5"}>{localTranslate("Baholanganlar", "Оценено", "Assessed")}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <CountUp end={appealStatisticsData?.results?.cancelled_appeals || 0} separator={" "}
                         className={"display-6 text-primary"} />
                <p className={"fs-5"}>{localTranslate("Rad etilganlar", "Отклоненный", "Rejected")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
