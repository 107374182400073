/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from "react";
import {useIntl} from "react-intl";
import {PageLink, PageTitle, PageToolbar} from "../../components/layout/core";
import Components from "../../components";
import {KTIcon} from "../../../_metronic/helpers";
import {useQuery} from "react-query";
import {getTeacherReport} from "../../api/_requests";
import {URL_TEACHER_REPORTS, URL_TEACHER_REPORTS_NEW} from "../../mock/page_urls";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {Empty, Loader} from "../../components/common";
import {mock} from "../../mock";
import {getLocalstorage} from "../../helpers";
import getDeviceType from "../../helpers/getDeviceType";

const ReportsPage: FC = () => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';
  const navigate = useNavigate();

  const {
    isMobile,
    isTablet,
    isDesktop
  } = getDeviceType()

  const { data: teacherReports, refetch: refetchReports, isLoading, isSuccess } = useQuery(
    ["teacher_reports", page],
      ()=>getTeacherReport(page)
  );

  const reportToolbars: Array<PageToolbar> = [
    {
      title: intl.formatMessage({id: "Shikoyat yo'llash"}),
      icon: "notepad-edit",
      isActive: true,
      additional: {
        onClick: () => navigate(URL_TEACHER_REPORTS_NEW),
      },
    },
  ];
  const reportsBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: "Shikoyatlar"}),
      path: "/",
      isSeparator: false,
      isActive: false,
    },
  ];
  const teacherReportsHeader = [
    { label: "№" },
    { label: intl.formatMessage({id: "Mavzusi"}) },
    { label: intl.formatMessage({id: "Shikoyat matni"}) },
    { label: intl.formatMessage({id: "Javob berilganmi"}) },
    { label: intl.formatMessage({id: "O'quv yili"}) },
    { label: intl.formatMessage({id: "Yaratilgan sana"}) },
    { label: intl.formatMessage({id: "Kerakli"}) },
  ];

  useEffect(() => {
    refetchReports();
  }, [page, refetchReports]);

  return (
    <>
      <PageTitle
        description={intl.formatMessage({id: "Shikoyatlar va ularning natijalari"})}
        breadcrumbs={reportsBreadCrumbs}
        toolbar={reportToolbars}
      />

      {isLoading ? <Loader height={"70vh"}/> : <div className="px-6 px-md-10">
        {
          teacherReports?.data.results.length > 0 ? (
              <Components.Table
                  header={teacherReportsHeader}
                  forPaginationData={teacherReports?.data}
                  title={intl.formatMessage({id: "Yuborilgan shikoyatlar"})}
                  body={teacherReports?.data.results.map((d) => ({
                    ...d,
                    actions: (
                        <div className="d-flex flex-row gap-2">
                          <Link to={`${URL_TEACHER_REPORTS}/${d.id}`} className={`btn ${(isDesktop || isTablet) && "btn-icon"} btn-secondary`}>
                            <KTIcon iconName="eye" className="fs-1"/>{isMobile && intl.formatMessage({id: "Ko'rish"})}
                          </Link>
                        </div>
                    ),
                  }))}
                  bodyKey={[...mock.tableOptions.teacherReportsBodyKey, "actions"]}
              />
          ) : <Empty title={intl.formatMessage({id: "Shikoyatlar mavjud emas!"})}/>
        }
      </div>}

    </>
  );
};

export {ReportsPage};
