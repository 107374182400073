import React, {useEffect, useState} from 'react'
import {Empty, Filter, Loader, Pagination} from "../../components/common";
import {PopularWorksCard} from "../../components/section/landing/popularWorks/popularWorksCard";
import {IFilters} from "../../types";
import {useIntl} from "react-intl";
import {useSearchParams} from "react-router-dom";
import {useDebounce} from "../../hooks";
import {useQuery} from "react-query";
import {getLandingPopularAppeals} from "../../api/_requests";
import {queryCacheOptions} from "../../mock/cacheOptions";
import {ScrollTopComponent} from "../../../_metronic/assets/ts/components";
import Components from "../../components";

export const HomePage = () => {
  const [filters, setFilters] = useState<IFilters>({
    region: null,
    district: null,
    science: null,
    search: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPageChanging, setIsPageChanging] = useState(false);

  const intl = useIntl();
  const page = searchParams.get('page') || '1';
  const params = new URLSearchParams(searchParams.toString());

  const debouncedFilters = useDebounce(filters, 500);

  const {
    data: popularAppeals,
    refetch: refetchPopularAppeals,
    isLoading: popularAppealsIsLoading,
  } = useQuery(
      ['getLandingPopularAppeals', debouncedFilters],
      () => getLandingPopularAppeals(debouncedFilters, params.get('page')),
      {
        ...queryCacheOptions,
        enabled: !!debouncedFilters,
      }
  );

  useEffect(() => {
    setIsPageChanging(true);
    refetchPopularAppeals().finally(() => setIsPageChanging(false));
  }, [page, refetchPopularAppeals]);

  const handleApplyFilters = (filterState: IFilters) => {
    const adjustedFilters = {
      ...filterState,
      science: filterState.science === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.science,
      region: filterState.region === intl.formatMessage({id: 'Tanlash'}) ? null : filterState.region,
      district: filterState.region === intl.formatMessage({id: 'Tanlash'}) || filterState.region === null ? null : filterState.district,
    };

    setFilters(adjustedFilters);
    setSearchParams({...Object.fromEntries(searchParams.entries()), page: '1'});
    refetchPopularAppeals();
  };

  const handleClearFilters = () => {
    setFilters({
      region: null,
      district: null,
      science: null,
      search: null,
    });
    refetchPopularAppeals();
  };

  useEffect(() => {
    ScrollTopComponent.goTop()
  }, []);

  return (
      <div className={"px-6 px-md-10"}>
        <div>
          <Components.section.landing.ResultsSection/>
        </div>

        <div className="my-20">
          <Filter onApply={handleApplyFilters} onClear={handleClearFilters} filterType="landing"/>
          {isPageChanging || popularAppealsIsLoading ? (
              <Loader height={'30vh'}/>
          ) : popularAppeals?.results?.length > 0 ? (
              <div className="row mt-5 row-gap-5">
                {popularAppeals?.results?.map((appeal, index) => (
                    <div key={index} className="col-sm-6 col-md-6 col-lg-4">
                      <PopularWorksCard appeal={appeal}/>
                    </div>
                ))}
              </div>
          ) : (
              <Empty/>
          )}

          <Pagination fetchData={refetchPopularAppeals} data={popularAppeals}/>
        </div>
      </div>
  )
}
