import { SidebarItemProps } from "../types/global";
import {
  URL_METHODIST_MAIN,
  URL_METHODIST_REPORTS,
  URL_TEACHER_MAIN,
  URL_TEACHER_REPORTS,
  PROFILE_SETTINGS,
  PROFILE_OVERVIEW,
  URL_ADMIN_REPORTS,
  URL_ADMIN_MAIN,
  URL_ADMIN_METHODISTS,
  URL_METHODIST_APPEALS,
  URL_ADMIN_APPEALS,
  URL_TEACHER_HOME,
} from "./page_urls";
import {localTranslate} from "../helpers";

export const methodistSidebarItems: SidebarItemProps[] = [
  {
    path: URL_METHODIST_MAIN,
    name: localTranslate("Bosh sahifa", "Главная страница", "Home"),
    icon: "home-3",
  },
  {
    path: URL_METHODIST_APPEALS,
    name: localTranslate("Arizalar", "Приложения", "Appeals"),
    icon: "file-added",
  },
  {
    path: URL_METHODIST_REPORTS,
    name: localTranslate("Shikoyatlar", "Жалобы", "Complaints"),
    icon: "question",
  },
  {
    blockTitle: localTranslate("Hisob", "Аккаунт", "Account"),
    path: PROFILE_OVERVIEW,
    name: localTranslate("Kabinet", "Кабинет", "Profile"),
    icon: "user-square",
  },
  {
    path: PROFILE_SETTINGS,
    name: localTranslate("Sozlamalar", "Настройки", "Settings"),
    icon: "gear",
  },
];

export const teacherSidebarItems: SidebarItemProps[] = [
  {
    path: URL_TEACHER_HOME,
    name: localTranslate("Bosh sahifa", "Главная страница", "Home page"),
    icon: "home-3",
  },

  {
    path: URL_TEACHER_MAIN,
    name: localTranslate("Mening arizam", "Мое приложение", "My appeals"),
    icon: "file-added",
  },
  // {
  //   path: URL_TEACHER_CHECK_MYSELF,
  //   name: "O'zingizni sinang",
  //   icon: "medal-star",
  // },
  {
    path: URL_TEACHER_REPORTS,
    name: localTranslate("Shikoyatlar", "Жалобы", "Complaints"),
    icon: "question",
  },
  {
    blockTitle: localTranslate("Hisob", "Аккаунт", "Account"),
    path: PROFILE_OVERVIEW,
    name: localTranslate("Kabinet", "Кабинет", "Profile"),
    icon: "user-square",
  },
  {
    path: PROFILE_SETTINGS,
    name: localTranslate("Sozlamalar", "Настройки", "Settings"),
    icon: "gear",
  },
];

export const adminSidebarItems: SidebarItemProps[] = [
  {
    path: URL_ADMIN_MAIN,
    name: localTranslate("Bosh sahifa", "Главная страница", "Home"),
    icon: "home-3",
  },
  {
    path: URL_ADMIN_APPEALS,
    name: localTranslate("Arizalar", "Приложения", "Appeals"),
    icon: "file-added",
  },
  {
    path: URL_ADMIN_METHODISTS,
    name: localTranslate("Metodistlar", "Методисты", "Methodists"),
    icon: "user-tick",
  },
  {
    path: URL_ADMIN_REPORTS,
    name: localTranslate("Shikoyatlar", "Жалобы", "Complaints"),
    icon: "question",
  },
  {
    blockTitle: localTranslate("Hisob", "Аккаунт", "Account"),
    path: PROFILE_OVERVIEW,
    name: localTranslate("Kabinet", "Кабинет", "Profile"),
    icon: "user-square",
  },
  {
    path: PROFILE_SETTINGS,
    name: localTranslate("Sozlamalar", "Настройки", "Settings"),
    icon: "gear",
  },
];
