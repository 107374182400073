import {RegionProps} from "app/types/region";
import {
  ADDRESS_DISTRICT,
  ADDRESS_REGIONS,
  ADDRESS_SCIENCES,
  APPEAL_ACADEMIC_YEAR,
  APPEAL_ANSWER,
  APPEAL_CREATE,
  APPEAL_INFO,
  APPEAL_QUESTIONS,
  APPEAL_STATUS,
  APPEALS,
  AUTH_SESSIONS,
  BASE_URL,
  CHANGE_NAME,
  CHANGE_PASSWORD,
  CHECK_USER_AUTH,
  CONFIRM,
  CONFIRM_RESET,
  CONTROL_SETTINGS,
  EDIT_PROFILE,
  EXPORT_TASK,
  GENERATE_CERTIFICATES,
  GET_CERTIFICATE,
  LANDING_APPEALS_STATISTICS,
  LANDING_FOOTERS,
  LANDING_LIMITED_POPULAR_APPEALS,
  LANDING_MAIN_PAGES,
  LANDING_METHODOLOGICAL_PRODUCTS,
  LANDING_NAVBARS,
  LANDING_POPULAR_APPEALS,
  LANDING_POPULARIZATION_PROCEDURES,
  LANDING_REGULATORY_DOCUMENTS,
  METHODS,
  MODERATOR_APPEAL_ANSWER,
  MODERATOR_APPEAL_UPDATE,
  MODERATOR_APPEALS,
  MODERATOR_APPEALS_EXPORT,
  MODERATOR_FILTER_REGIONS,
  MODERATOR_FILTER_SCIENCE,
  MODERATOR_FILTER_USERS,
  MODERATOR_MODERATORS,
  MODERATOR_QUESTIONS,
  MODERATOR_REPORT_CHANGE_NAME,
  MODERATOR_REPUBLIC_RATE,
  MODERATOR_STATICTICS,
  MY_REPORT,
  NOTIFICATIONS,
  REGISTER,
  REPORT,
  REPORTS_STATUS,
  RESET_PASSWORD,
} from "./URLs";
import {JshirRegisterRequestProps,} from "app/types/jshirregister";
import onlyNumber from "app/helpers/onlyNumber";
import moment from "moment";
import axios, {AxiosResponse} from "axios";
import {api, apiWithoutToken} from ".";
import {formatDateToDDMMYYYY} from "../helpers";
import {
  IAppealsStatisticsResponse,
  IFootersResponse,
  IHeaderResponse,
  ILimitedAppealsResponse,
  IMethodologicalProductsResponse,
  INavbarsResponse,
  IPopularizationProceduresResponse,
  IRegulatoryDocumentResponse,
  ModeratorAppealProps,
  ModeratorProps,
  ModeratorReportProps,
  NotificationProps,
  ReportChangeNameProps
} from "../types";

export const getRegions = async () => {
  const {data} = await api.get<RegionProps[]>(
      ADDRESS_REGIONS
  );
  return data;
};

export const getModeratorRegions = async () => {
  const {data} = await api.get(
      MODERATOR_FILTER_REGIONS
  );
  return data;
};

export const getSciences = async () => {
  const {data} = await axios.get(
      `${BASE_URL}${ADDRESS_SCIENCES}`
  );
  return data;
};

export const getMethods = async () => {
  const {data} = await api.get(
      `${BASE_URL}${METHODS}`
  );
  return data;
};

export const getDistrict = async (id: number | string) => {
  const {data} = await api.get(
      `${BASE_URL}${ADDRESS_DISTRICT}${id}/`
  );
  return data;
};

export const getAppealStatus = async () => {
  const {data} = await api.get(
      APPEAL_STATUS
  );
  return data;
};

export const getReportStatus = async () => {
  const {data} = await api.get(
      REPORTS_STATUS
  );
  return data;
};

export const requestJshirRegister = async (
    jshir: string,
    firstname: string,
    lastname: string,
    familiyname: string,
    birthdate: string,
    gender: string,
    region: string,
    district: string,
    organization: string,
    password: string,
    phone: string
) => {
  const data = await axios.post<JshirRegisterRequestProps>(
      `${BASE_URL}${REGISTER}`,
      {
        birthday: moment(birthdate[0]).format("YYYY-MM-DD"),
        family_name: familiyname,
        first_name: firstname,
        gender: gender,
        jshir: jshir,
        last_name: lastname,
        region: region,
        district: district,
        organization: organization,
        password: password,
        phone: onlyNumber(phone),
      }
  );
  return data;
};

async function getIpAndLocation() {
  const ipResponse = await fetch('https://api.ipify.org?format=json');
  const ipData = await ipResponse.json();
  const ip = ipData.ip;

  const locationResponse = await fetch('https://ipapi.co/json/');
  const locationData = await locationResponse.json();
  const location = `${locationData.city}, ${locationData.region}, ${locationData.country_name}`;

  return {ip, location};
}

export const requestJshirRegisterConfirmPhone = async (
    code: number,
    phone: string
) => {
  try {
    const deviceName = navigator.userAgent;
    const {ip, location} = await getIpAndLocation();
    const response = await axios.post(`${BASE_URL}${CONFIRM}`, {
      code,
      phone,
      device: deviceName,
      ip_address: ip,
      location: location,
    });

    return response;
  } catch (error) {

    // console.error('Error in requestJshirRegisterConfirmPhone:', error);
    throw error;
  }
};

export const requestRestorePasswordNumber = async (
    phone: string,
    otp: number
) => {
  const data = await axios.post(`${BASE_URL}${RESET_PASSWORD}`, {
    phone,
    code: otp,
  });
  return data;
};

export const checkUserPhoneExist = async (
    phone: string,
) => {
  const data = await axios.post(`${BASE_URL}${CHECK_USER_AUTH}`, {
    phone,
  });
  return {data};
};


export const requestRestorePAsswordNew = async (password: string, password2: string, phone: string, code: number) => {
  const data = await axios.post(`${BASE_URL}${CONFIRM_RESET}`, {
    code,
    phone,
    password,
    password2
  });
  return data;
};

export const getTeacherAppeal = async (page) => {
  try {
    const params = {page}
    const data = await api.get(APPEAL_INFO, {params})
    return data
  } catch (error) {

    // console.error('Error getting appeal info:', error);
    throw error;
  }
}

export const getControlSettings = async () => {
  try {
    const {data} = await api.get(CONTROL_SETTINGS)
    return data
  } catch (error) {
    // console.log("Error getting appointments", error)
    throw error
  }
}

export const getTeacherAppealId = async (id: any) => {
  const data = await api.get(`${APPEAL_INFO}?id=${id}`)
  return data
}

export const getCertificate = async (id: any) => {
  try {
    const {data} = await api.get(`${GET_CERTIFICATE}${id}`)
    return data
  } catch (error) {
    // console.error('Error getting certificate:', error);
    // console.log("dsdsdsds")
    // throw error;
  }
}

export const postCertificate = async (id: any) => {
  const data = await api.post(`${GENERATE_CERTIFICATES}`, {
    appeal: id
  })
  return data
}

export const postTeacherAppeal = async (aboutFormData: any, pedFormData: any, dataFormData: any) => {
  const formData = new FormData();
  formData.append('name', aboutFormData.name);
  formData.append('description', aboutFormData.description);
  formData.append('method', aboutFormData.type);
  formData.append('science', aboutFormData.science)
  formData.append('file', aboutFormData.methodFile);
  formData.append('decision_date', formatDateToDDMMYYYY(pedFormData.decision_date));
  formData.append('decision_number', pedFormData.decision_number);
  formData.append('decision_file', pedFormData.decision_file);
  formData.append('diploma_file', dataFormData.diploma_file);
  formData.append('objective_file', dataFormData.objective_file);
  const data = await api.post(`${APPEAL_CREATE}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
}

export const getTeacherReport = async (page) => {
  const params = {
    page
  }
  const data = await api.get(MY_REPORT, {params})
  return data
}

export const postTeacherReport = async (
    message: string,
    title: string,
    phone: string,
    address: string,
    file: File | null
) => {
  const formData = new FormData();
  formData.append('message', message);
  formData.append('title', title);
  formData.append('phone', phone);
  formData.append('address', address);
  if (file) formData.append('file', file);

  try {
    const response = await api.post(`${BASE_URL}${REPORT}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {

    // console.error('Error posting teacher report:', error);
    throw error;
  }
};

export const getTeacherReportId = async (id: any) => {
  const data = await api.get(`${MY_REPORT}?report_id=${id}`)
  return data
}

export const getTeacherQuestions = async () => {
  const data = await api.get(APPEAL_QUESTIONS)
  return data.data
}

export const getModeratorQuestions = async () => {
  const data = await api.get(MODERATOR_QUESTIONS)
  return data.data
}

export const postSubmitSolution = async (answerData, role, id) => {
  try {
    const response = await api.post(role === "moderator" ? `${MODERATOR_APPEAL_ANSWER}${id}/` : role === "super_admin" ? `${MODERATOR_APPEAL_ANSWER}${id}/` : role === "user" ? APPEAL_ANSWER : APPEAL_ANSWER, answerData);
    return response;
  } catch (error) {


    // console.error('Error submitting solution:', error);
    return false;
  }
}

export const postChangePassword = async (data: {
  old_password: string;
  new_password: string;
  new_password2: string;
}): Promise<AxiosResponse> => {
  try {
    const response = await api.post(CHANGE_PASSWORD, data);
    return response;
  } catch (error) {

    // console.error('Error submitting change password:', error);
    throw error;
  }
};

export const getSessions = async () => {
  try {
    const response = await api.get(AUTH_SESSIONS);
    return response;
  } catch (error) {

    // console.error('Error getting sessions:', error);
    throw error;
  }
}

export const getAcademicYears = async () => {
  try {
    const response = await api.get(APPEAL_ACADEMIC_YEAR);
    return response;
  } catch (error) {

    // console.error('Error getting academic years:', error);
    throw error;
  }
}

export const getStatics = async (type) => {
  try {
    const response = await api.post(MODERATOR_STATICTICS, type);
    return response;
  } catch (error) {

    // console.error('Error getting statics:', error);
    throw error;
  }
}

export const getTaskID = async (selected: number[]) => {
  try {
    const response = await api.post(MODERATOR_APPEALS_EXPORT, {
      appeals: selected,
      export_type: "ID"
    });

    return response;
  } catch (error) {

    // console.error('Error exporting selected:', error);
    throw error;
  }
}

export const postExportSelected = async (id) => {
  try {
    const response = await api.get(`${EXPORT_TASK}${id}/`);

    return response;
  } catch (error) {

    // console.error('Error exporting selected:', error);
    throw error;
  }
}

export const postSendMessage = async (title, reason, file: File | null) => {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('reason', reason);
  if (file) {
    formData.append('image', file);
  }
  try {
    const response = await api.post(CHANGE_NAME, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {

    // console.error('Error submitting solution:', error);
    return false;
  }
}

export const updateProfileAvatar = async (avatar: File | null) => {
  try {
    let response;

    if (avatar) {
      const formData = new FormData();
      formData.append('avatar', avatar);

      response = await api.put(`${BASE_URL}${EDIT_PROFILE}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
        },
      });
    } else {
      response = await api.put(`${BASE_URL}${EDIT_PROFILE}`, {}, {
        headers: {
          'Accept': 'application/json',
        },
      });
    }

    return response.data;
  } catch (error) {

    // console.error('Error updating profile avatar:', error);
    throw error;
  }
};

export const getModeratorAppeals = async (filters: any = {}, page) => {
  const {
    ball_min, ball_max, jshir, status, year, region, district, search
  } = filters;

  const params: any = {
    page,
    ...(ball_min !== null && {ball_min}),
    ...(ball_max !== null && {ball_max}),
    ...(jshir && {jshir}),
    ...(status && {status}),
    ...(year && {academic_year: year}),
    ...(region && {region}),
    ...(district && {district}),
    ...(search && {search}),
  };

  const {data} = await api.get(APPEALS, {params});
  return data;
};

export const getModeratorReports = async (filters: any = {}, page) => {
  const {
    ball_min, ball_max, jshir, status, year, region, district, search
  } = filters;

  const params: any = {
    page,
    ...(ball_min !== null && {ball_min}),
    ...(ball_max !== null && {ball_max}),
    ...(jshir && {jshir}),
    ...(status && {status}),
    ...(year && {academic_year: year}),
    ...(region && {region}),
    ...(district && {district}),
    ...(search && {search}),
  };

  const {data} = await api.get(REPORT, {params});
  return data;
};

export const getAllModerators = async (filters: any = {}, page) => {
  try {
    const {
      search, is_active
    } = filters;
    const is_active_param = is_active === 'UNBLOCKED' ? true : (is_active === 'BLOCKED' ? false : undefined);

    const params: any = {
      page,
      ...(search && {search}),
      ...(is_active_param !== undefined && {is_active: is_active_param}),
    };

    const response = await api.get(`${MODERATOR_MODERATORS}`, {params});
    return response.data;
  } catch (error) {

    // console.error('Error getting moderator info:', error);
    throw error;
  }
};

export const getAllUsers = async (search: string = '') => {
  try {
    const response = await api.get(`${MODERATOR_FILTER_USERS}`, {
      params: {search},
    });
    return response.data;
  } catch (error) {

    // console.error('Error getting all users:', error);
    throw error;
  }
};

export const getModeratorScience = async () => {
  try {
    const {data} = await api.get(
        `${MODERATOR_FILTER_SCIENCE}`
    );
    return data;
  } catch (error) {

    // console.error('Error getting district:', error);
    throw error;
  }
};

export const getModeratorReportChangeName = async (filters: any = {}, page: string) => {
  const {
    status, search
  } = filters;

  const params: any = {
    page,
    ...(status && {status}),
    ...(search && {search}),
  };

  const {data} = await api.get(MODERATOR_REPORT_CHANGE_NAME, {params});
  return data;
};

export const getModeratorAppealId = async (id: any) => {
  try {
    const response = await api.get<ModeratorAppealProps>(`${MODERATOR_APPEALS}${id}`)
    return response.data
  } catch (error: any) {

    // console.error('Error getting appeal info:', error);
    throw error;
  }
}

export const getModeratorReportId = async (id: any) => {
  try {
    const response = await api.get<ModeratorReportProps>(`${REPORT}${id}`)
    return response.data
  } catch (error) {

    // console.error('Error getting appeal info:', error);
    throw error;
  }
}

export const getModeratorViewId = async (id: any) => {
  try {
    const response = await api.get<ModeratorProps>(`${MODERATOR_MODERATORS}${id}`)
    return response.data
  } catch (error) {

    // console.error('Error getting appeal info:', error);
    throw error;
  }
}

export const updateModeratorViewId = async (id: any, isActive) => {
  try {
    const response = await api.patch<ModeratorProps>(`${MODERATOR_MODERATORS}${id}/`, {is_active: isActive})
    return response
  } catch (error) {

    // console.error('Error getting appeal info:', error);
    throw error;
  }
}

export const postModerator = async (data) => {
  try {
    const response = await api.post(`${MODERATOR_MODERATORS}`, data)
    return response
  } catch (error) {

    // console.error('Error getting appeal info:', error);
    throw error;
  }
}

export const getModeratorReportChangeNameId = async (id: any) => {
  try {
    const response = await api.get<ReportChangeNameProps>(`${MODERATOR_REPORT_CHANGE_NAME}${id}`)
    return response.data
  } catch (error) {

    // console.error('Error getting appeal info:', error);
    throw error;
  }
}

export const updateModeratorReportChangeNameId = async (id: any, values?: any, status?: "APPROVED" | "REJECTED") => {
  const data = status === "APPROVED" ? {
    status: "APPROVED",
    user: {
      first_name: values.first_name,
      last_name: values.last_name,
      family_name: values.family_name,
      contact_phone: values.phone,
      address: {
        _region: values.region,
        _district: values.district,
        _school: values.organization
      }
    }
  } : {
    status: "REJECTED"
  }

  try {
    const response = await api.put(`${MODERATOR_REPORT_CHANGE_NAME}${id}/`, data !== null && data)
    return response
  } catch (error) {

    // console.error('Error getting appeal info:', error);
    throw error;
  }
}

export const updateModeratorReport = async (
    id,
    status,
    comment,
    file,
    ball?: any
) => {
  try {
    const data = new FormData();


    if (status) data.append('status', status);
    if (comment) data.append('response', comment);
    if (file) data.append('response_file', file);
    if (ball) data.append('ball', ball);

    const response = await api.patch(`${REPORT}${id}/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getNotification = async () => {
  try {
    const response = await api.get<NotificationProps>(`${NOTIFICATIONS}`)
    return response.data
  } catch (error) {

    // console.error('Error getting notification:', error);
    throw error;
  }
}

export const updateNotificationStatus = async (id: number | string) => {
  try {
    const response = await api.patch(`${NOTIFICATIONS}${id}/`, {is_read: true})
    return response
  } catch (error) {

    // console.error('Error getting notification:', error);
    throw error;
  }
}

export const moderatorUpdateAppeal = async (id: number | string, comment: string, status: string) => {
  try {
    const response = await api.patch<{ status: string, comment: string }>(`${MODERATOR_APPEAL_UPDATE}${id}/`, {
      comment,
      status
    })
    return response
  } catch (error) {

    // console.error('Error getting appeal info:', error);
    throw error;
  }
}

// <--- LANDING API --->
export const getLandingNavbars = async () => {
  try {
    const response = await apiWithoutToken.get<INavbarsResponse>(`${BASE_URL}${LANDING_NAVBARS}`);
    return response.data;
  } catch (error) {

    // console.error('Error getting landing navbars:', error);
    throw error;
  }
}

export const getLandingHeaders = async (type) => {
  try {
    const response = await apiWithoutToken.get<IHeaderResponse>(`${BASE_URL}${LANDING_MAIN_PAGES}?types=${type}`);
    return response.data
  } catch (error) {

    // console.error('Error getting landing navbars:', error);
    throw error;
  }
}

export const getLandingAppealStatistics = async (selectedAcademicYear: string) => {
  try {
    const response = await apiWithoutToken.get<IAppealsStatisticsResponse>(`${BASE_URL}${LANDING_APPEALS_STATISTICS}?academic_year=${selectedAcademicYear}`);
    return response.data
  } catch (error) {

    // console.error('Error getting landing navbars:', error);
    throw error;
  }
}

export const getLandingRegulatoryDocuments = async () => {
  try {
    const response = await apiWithoutToken.get<IRegulatoryDocumentResponse>(`${BASE_URL}${LANDING_REGULATORY_DOCUMENTS}`);
    return response.data
  } catch (error) {

    // console.error('Error getting landing navbars:', error);
    throw error;
  }
}

export const getLandingPopularizationProcedures = async () => {
  try {
    const response = await apiWithoutToken.get<IPopularizationProceduresResponse>(`${BASE_URL}${LANDING_POPULARIZATION_PROCEDURES}`);
    return response.data
  } catch (error) {

    // console.error('Error getting landing navbars:', error);
    throw error;
  }
}

export const getLandingMethodologicalProducts = async () => {
  try {
    const response = await apiWithoutToken.get<IMethodologicalProductsResponse>(`${BASE_URL}${LANDING_METHODOLOGICAL_PRODUCTS}`);
    return response.data
  } catch (error) {

    // console.error('Error getting landing navbars:', error);
    throw error;
  }
}

export const getLandingLimiterPopularAppeals = async (limit) => {
  try {
    const response = await apiWithoutToken.get<ILimitedAppealsResponse>(`${BASE_URL}${LANDING_LIMITED_POPULAR_APPEALS}?limit=${limit}`);
    return response.data
  } catch (error) {

    // console.error('Error getting landing navbars:', error);
    throw error;
  }
}

export const getLandingFooters = async () => {
  try {
    const response = await apiWithoutToken.get<IFootersResponse>(`${BASE_URL}${LANDING_FOOTERS}`);
    return response.data
  } catch (error) {

    // console.error('Error getting landing navbars:', error);
    throw error;
  }
}

export const getLandingPopularAppeals = async (filters: any = {}, page) => {
  try {
    const {
      region, district, search, science
    } = filters;

    const params: any = {
      page_size: 9,
      page,

      ...(region && {region}),
      ...(district && {district}),
      ...(science && {science}),
      ...(search && {search}),
    };
    const response = await apiWithoutToken.get(`${BASE_URL}${LANDING_POPULAR_APPEALS}`, {params});
    return response.data
  } catch (error) {

    // console.error('Error getting landing navbars:', error);
    throw error;
  }
}

export const pollCertificateStatus = async (teacherId: string, pollInterval: number = 3000) => {
  return new Promise<void>((resolve, reject) => {
    const intervalId = setInterval(async () => {
      try {
        const certificateData = await getCertificate(teacherId);
        if (certificateData?.status === "success") {
          clearInterval(intervalId);
          window.open(certificateData?.results[0]?.file_url, "_blank");
          resolve();
        }
      } catch (error) {
        clearInterval(intervalId);
        reject(error);
      }
    }, pollInterval);
  });
};

export const postRepublicRatingBall = async (id: number | string, values) => {
  try {
    const response = await api.patch(`${MODERATOR_REPUBLIC_RATE}${id}/`, {country_ball: values.country_ball});
    return response;
  } catch (error) {

    // console.error('Error submitting solution:', error);
    return false;
  }
}
